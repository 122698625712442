<template>
  <section id="content" style="margin-bottom: 80px ">
    <v-card
      class="mx-auto py-1 mb-2 border-radius 0px;"
      elevation="24"
      light
      max-width="95%"
      style="margin: -64px 70px 0px 0px; border-radius 0px"
      v-bind="$attrs"
      tile
      outlined
    >
      <v-sheet max-width="1000" class="mx-auto py-0 py-md-6">
        <v-container id="vineyards">
          <v-card-title
            :class="[$vuetify.breakpoint.mdAndUp ? 'display-2' : 'display-1']"
            class="justify-center font-weight-bold grey--text text--darken-3 mb-3 text-center"
          >
            About
          </v-card-title>
          <v-card-text class="text-center title font-weight-light mx-auto mb-5">
            Situated in a tropical coastal setting on the north coast of Kwa
            Zulu Natal, the little gem "Twinstreams" is the Dream of Africa. A
            luxuriant and peaceful paradise created by Dr Ian Garland, ably
            sustained by Jonathan and Pearl Cromhout you will find nestled in an
            oasis, a vast rehabilitated forest on a farm in the Siyaya catchment
            on the doorstep of the small seaside town of Mtunzini. Visit the
            nursery and be amazed by the tranquillity and incredible beauty of
            this unique indigenous planting, together with all the fauna which
            has now found a place to share with us. Butterflies drift over
            fragrant flowers, birds call incessantly. Come share this sense of
            Place, "Twinstreams", take a small piece away in your hand and your
            heart. Take that seed which was passed on and create your own
            paradise. We specialise in the propagation of a wide range of high
            quality, hardy indigenous species from Zululand, suitable to a wide
            range of areas in South Africa. With over 600 species to choose
            from, Twinstreams has one of the most comprehensive collections of
            indigenous species in the country for the discerning gardener,
            landscaper, developer and nurseries. We collect all our own seeds in
            our forest and locally, manufacture our own growing media and
            recycle our used plant bags, ensuring our in-house processes are
            constantly improving and are green. With the assistance of our well
            trained staff, come and see how you can create a living garden full
            of diversity and water wise plants, learn about the invasive species
            threatening our environment, how to garden without pesticides and
            improve your soils organically. In addition to this incredible
            range, we offer full Turnkey landscaping services, rehabilitation
            and environmental assistance at a very competitive price.
          </v-card-text>
        </v-container>

        <v-card class="mx-auto" height="75vh" width="80vw" tile>
          <v-carousel
            cycle
            height="75vh"
            width="80vw"
            hide-delimiters
            show-arrows-on-hover
          >
            <v-carousel-item
              v-for="(item, i) in items"
              :key="i"
              :src="item.src"
            />
          </v-carousel>
        </v-card>

        <section id="details" class="mx-auto pt-5 pb-0 mb-2">
          <v-container>
            <v-row justify="center">
              <v-col
                v-for="([icon, info1, info2, href], i) in details"
                :key="i"
                cols="12"
                md="3"
              >
                <div class="text-center">
                  <a
                    style="text-decoration: none;"
                    :href="href"
                    target="_blank"
                    rel="noopener"
                  >
                    <v-icon
                      color="thirdary"
                      size="64"
                      class="mb-3"
                      v-text="icon"
                    />

                    <div color="thirdary" class="mb-2 title" v-text="info1" />
                    <div color="thirdary" v-text="info2" />
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </section>
      </v-sheet>
      <v-sheet class="mx-auto py-0 mb-2" color="grey lighten-2">
        <p style="text-align: center;">
          <strong>Open</strong>
          <br />
          <em>Monday:</em> closed
          <br />
          <em>Tuesday – Friday:</em> 07:00-16:00
          <br />
          <em>Saturday & Sunday & Public Holidays:</em> 07:00-13:00
          <br />
        </p>
      </v-sheet>
    </v-card>
  </section>
</template>

<script>
export default {
  name: "SampleContents",
  data: () => ({
    items: [
      {
        src: require("@/assets/Main/about/about (3).webp")
      },
      {
        src: require("@/assets/Main/about/about (8).webp")
      },
      {
        src: require("@/assets/Main/about/about (9).webp")
      },
      {
        src: require("@/assets/Main/about/about.webp")
      }
    ],
    details: [
      [
        "mdi-crosshairs-gps",
        "Twinstreams Indigenous Nursery",
        "Mtunzini, KZN, 3867",
        "https://www.google.com/maps/place/Twinstreams+Indigenous+Nursery/@-28.9866208,31.725773,17z/data=!3m1!4b1!4m5!3m4!1s0x1efa0207765001cd:0xb1ef24fea994c6bf!8m2!3d-28.9866208!4d31.7279617"
      ],
      [
        "mdi-email",
        "info@twinstreams.co.za ",
        "",
        "mailto:info@twinstreams.co.za "
      ],
      ["mdi-phone", "074 189 6354", "", "tel:+27-74-189-6354"]
    ],
    socials: [
      [
        "https://www.facebook.com/Twinstreamsindigenousnursery",
        "mdi-facebook",
        "Facebook"
      ],
      [
        "https://www.instagram.com/twinstreamsnursery/",
        "mdi-instagram",
        "Instagram"
      ],
      ["https://blockhousekitchen.co.za/", "mdi-web", "Website"]
    ]
  })
};
</script>

<style>
.v-card {
  border-radius: 0px;
}
</style>
